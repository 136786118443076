<!--
 * @Author: PC
 * @Date: 2024-10-18 11:23:04
 * @LastEditTime: 2024-10-27 18:14:48
 * @Description: 场景1组件
-->
<template>
    <div class="scene1">
        <div class="scene_bg"></div>
        <div class="banner">
            <img src="@/assets/images/home/scene1/banner.png" />
        </div>
        <OperatingTips animationName="scene1_mouse" />
    </div>
</template>

<script>
import OperatingTips from "@/components/OperatingTips.vue";
import _ from "lodash";

export default {
    name: "Scene1",
    components: { OperatingTips },
    data() {
        return {};
    },

    mounted() {
        setTimeout(() => {
            document.addEventListener("wheel", this.throttledOnWheel);
        }, 800);
    },
    beforeDestroy() {
        this.$el.classList.add("fade-out");
        // 移除事件监听
        document.removeEventListener("wheel", this.throttledOnWheel);
    },
    methods: {
        throttledOnWheel: _.throttle(
            function (event) {
                const direction = event.deltaY > 0 ? 1 : -1; // 向下滚动时方向为 1
                if (direction === 1) {
                    this.$emit("wheelNext");
                }
            },
            1000,
            { trailing: false }
        ),
    },
};
</script>

<style lang="less" scoped>
.scene1 {
    width: 100%;
    height: 100vh;
    perspective: 500px;

    overflow: hidden;
    .scene_bg {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background-image: url("~@/assets/images/home/scene1/bg.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }
    .banner {
        width: 600px;
        height: 196px;
        position: absolute;
        top: 320px;
        left: 50%;
        transform: translateX(-50%);
        img {
            width: 100%;
            height: 100%;
            animation: fade_in_up 2s ease forwards;
        }
    }
}

</style>
