<!--
 * @Author: PC
 * @Date: 2024-10-11 13:47:56
 * @LastEditTime: 2024-10-29 12:00:13
 * @Description: 头部组件
-->

<template>
    <div class="header-component">
        <div class="header_inner">
            <img class="logo" src="@/assets/images/home/logo.png" @click="handleHome" />
            <div class="nav">
                <div class="nav-i" :class="{ on: active != 2 }" data-hover="true" @click="handleHome">{{ $t("header_nav_home") }}</div>
                <div class="nav-i" :class="{ on: active === 2 }" data-hover="true" @click="handleTree">{{ $t("header_nav_tree") }}</div>
                <div class="nav-i" data-hover="true" @click="handleHistory">{{ $t("header_nav_conference") }}</div>
                <div class="line" />
                <div class="language" data-hover="true" @click="handleChangeLanguage">{{ $t("language") }}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Header",
    props: {
        active: null,
    },
    data() {
        return {};
    },

    mounted() {},

    methods: {
        handleHome() {
            this.$emit("update:active", 0);
        },

        handleTree() {
            this.$emit("update:active", 2);
        },

        handleHistory() {
            const href = this.$i18n.locale === "zh" ? "./history/pc/review.html" : "./history/pc/en/review.html";
            window.location.href = href;
        },

        handleChangeLanguage() {
            const locale = this.$i18n.locale === "zh" ? "en" : "zh";
            window.location.reload();
            window.localStorage.setItem("locale", locale);
        },
    },
};
</script>
<style lang="less" scoped>
.header-component {
    position: fixed;
    top: 0;
    width: 100%;
    height: 88px;
    z-index: 3;
    padding: 0 44px;
    box-sizing: border-box;
    .header_inner {
        width: 100%;
        height: 100%;
        max-width: 1080px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .logo {
            height: 38px;
        }
        .nav {
            display: flex;
            align-items: center;
            font-size: 16px;

            .nav-i {
                margin: 0 8px;
                padding: 10px 8px;
                color: #fff;
                transition: opacity 0.3s;
                position: relative;
                &:after {
                    content: "";
                    position: absolute;
                    left: 0;
                    bottom: -10px;
                    width: 100%;
                    height: 1px;
                    background: #fff;
                    transform: scaleX(0);
                    transition: all 0.3s;
                }
                &.on {
                    &:after {
                        transform: scaleX(1);
                    }
                }
                &:hover {
                    &:after {
                        transform: scaleX(1);
                    }
                }
            }
            .line {
                height: 14px;
                border-right: 1px solid #fff;
                margin: 2px 16px 0 8px;
            }
            .language {
                color: #fff;
            }
        }
    }
}
</style>
