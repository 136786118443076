<!--
 * @Author: PC
 * @Date: 2024-10-18 11:23:04
 * @LastEditTime: 2024-10-31 17:56:45
 * @Description: 场景1组件
-->
<template>
    <div ref="scene4" class="scene4 no_scrollbar" @mousemove="handleMouseMove">
        <div ref="scene4BG" class="page_bg">
            <div class="sequence_frame-bg"></div>
            <!-- <FrameAnimation animationName="scene4_bg" :frames="bg" :duration="8" width="100%" height="100%" class="sequence_frame-bg" /> -->
        </div>

        <div class="qr_code">
            <img src="@/assets/images/home/scene4/qr_code.png" class="icon" />
            <div class="name">扫码体验</div>
        </div>

        <div class="photo_list">
            <div class="photo_list_inner">
                <div v-for="(item, index) in photoList" :key="index" class="list_item">
                    <img :src="item.banner" class="cover" ref="cover" />
                    <!-- <div class="cover" ref="cover" /> -->
                    <div ref="label_warpper" class="label_warpper" :class="{ label_warpper_show: labelStatus[`label_warpper${index}`] }" :id="`label_warpper${index}`">
                        <div class="label label1" v-html="item.label"></div>
                        <div class="label label2 gradient_text fade" v-html="item.label"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import FrameAnimation from "@/components/FrameAnimation.vue";
import "aos/dist/aos.css"; // 引入AOS的CSS样式
import _ from "lodash";
import { gsap } from "gsap";

export default {
    name: "Scene4",

    components: {
        FrameAnimation,
    },

    data() {
        return {
            photoList: [
                {
                    banner: require(`@/assets/images/home/scene4/banner0.gif`),
                    label: "<div><span>数据</span><span>来源于</span><span>人类权</span><span>威科技史料、</span><div><span>百科、</span><span>教材和</span><span>论文</span></div>",
                },
                {
                    banner: require(`@/assets/images/home/scene4/banner1.gif`),
                    label: "<div><span>混元大模型</span><span>百万级数据中</span><span>梳理出了</span><span>人类历史</span></div><span>发展的</span><span>重要科技事件</span></div>",
                },
                {
                    banner: require(`@/assets/images/home/scene4/banner2.gif`),
                    label: "<div><span>绘制</span><span>人类科技史</span><span>可视</span><span>图谱</span></div>",
                },
            ],
            labelStatus: {},
        };
    },

    mounted() {
        this.scrollAni();

        document.body.style.overflow = "hidden";
        setTimeout(() => {
            document.body.style.overflow = "auto"; // 或者 'scroll'
            document.addEventListener("wheel", this.throttledOnWheel);
        }, 800);
    },

    beforeDestroy() {
        // 移除事件监听
        document.removeEventListener("wheel", this.throttledOnWheel);
    },

    methods: {
        throttledOnWheel: _.throttle(
            function (event) {
                const direction = event.deltaY > 0 ? 1 : -1; // 向下滚动时方向为 1
                if (direction === -1 && this.$refs.scene4.scrollTop === 0) {
                    this.$emit("wheelLast");
                }
            },
            1000,
            { trailing: false }
        ),

        scrollAni() {
            let _this = this;
            const observer = new IntersectionObserver(
                (entries) => {
                    entries.forEach((entry) => {
                        _this.$set(_this.labelStatus, entry.target.id, entry.isIntersecting);
                    });
                },
                {
                    threshold: 0.1, // 触发的可见度百分比
                }
            );

            // 观察目标元素
            const list = this.$refs.label_warpper;
            list.forEach((item) => {
                observer.observe(item);
            });
        },

        handleMouseMove(e) {
            const x = e.clientX / window.innerWidth - 0.5;
            const y = e.clientY / window.innerHeight - 0.5;
            gsap.to(this.$refs.cover, {
                x: x * 75, // 根据鼠标位置调整 x 位移
                y: y * 75, // 根据鼠标位置调整 y 位移
                duration: 0.2,
                ease: "power1.out",
            });
            gsap.to(this.$refs.label_warpper, {
                x: x * 45, // 根据鼠标位置调整 x 位移
                y: y * 45, // 根据鼠标位置调整 y 位移
                duration: 0.2,
                ease: "power1.out",
            });
            gsap.to(this.$refs.scene4BG, {
                x: x * 20, // 根据鼠标位置调整 x 位移
                y: y * 20, // 根据鼠标位置调整 y 位移
                duration: 0.1,
                ease: "power1.out",
            });
        },
    },
};
</script>

<style lang="less">
.scene4 {
    margin-top: 100px;
    width: 100%;
    height: calc(100vh - 100px);
    position: relative;
    overflow-y: scroll;
    overflow-x: hidden;
    .qr_code {
        position: fixed;
        bottom: 78px;
        right: 10px;

        z-index: 3;
        @media screen and (min-width: 1200px) {
            right: 60px;
        }
        @media screen and (min-width: 1366px) {
            right: 110px;
        }
        @media screen and (min-width: 1920px) {
            right: 160px;
        }

        .icon {
            width: 135px;
            height: 135px;
        }
        .name {
            text-align: center;
            color: #efefefcc;
            font-family: "PingFang SC";
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 3.36px;
        }
    }
    .page_bg {
        position: fixed;
        top: -20px;
        left: -20px;
        right: -20px;
        bottom: -20px;

        .sequence_frame-bg {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: url("~@/assets/images/home/scene3/bg1.png") no-repeat center, url("~@/assets/images/home/scene3/bg0.png") no-repeat center;
            background-size: cover, cover;
        }
    }

    .photo_list {
        width: 100%;
        position: absolute;
        z-index: 2;
        top: 200px;
        left: 0;
        .photo_list_inner {
            margin: 0 auto;
            width: 1003px;
            transform: scale(0.5);
            transform-origin: top center;
            @media screen and (min-width: 1200px) {
                transform: scale(0.7);
            }
            @media screen and (min-width: 1366px) {
                transform: scale(0.8);
            }
            @media screen and (min-width: 1920px) {
                transform: scale(1);
            }
            .list_item {
                margin-bottom: 200px;
                position: relative;
                opacity: 0;
                animation: photo_item 1s ease-in-out forwards;

                .cover {
                    background: linear-gradient(270deg, #535c9f 0%, #0d0f1d 100%);
                    border: none;
                    border-radius: 12px;
                }
                .label_warpper {
                    position: absolute;
                    .label {
                        position: relative;
                        font-size: 32px;
                        font-weight: 600;
                        line-height: 60px;
                        letter-spacing: 5px;

                        span {
                            display: inline-block;
                        }
                    }
                    .label2 {
                        position: absolute;
                        top: 0;
                        left: 0;
                        opacity: 0;
                    }
                }
                .label_warpper_show {
                    .label1 {
                        span {
                            color: #6f93f7;
                            &:nth-child(1) {
                                animation: blur-text 1.5s linear alternate;
                            }
                            &:nth-child(2) {
                                animation: blur-text2 2s linear alternate;
                            }
                            &:nth-child(3) {
                                animation: blur-text 1.8s linear alternate;
                            }
                            &:nth-child(4) {
                                animation: blur-text2 2.2s linear alternate;
                            }
                        }
                    }

                    .fade {
                        animation: fade-text 2s linear forwards;
                        animation-delay: 2s;
                    }
                }
            }
            .list_item:nth-child(1) {
                .cover {
                    width: 500px;
                    height: 460;
                }
                .label_warpper {
                    bottom: 20px;
                    right: 46px;
                }
            }
            .list_item:nth-child(2) {
                display: flex;
                justify-content: flex-end;
                animation-delay: 0.5s;
                margin-bottom: 264px;
                .cover {
                    width: 600px;
                    height: 368px;
                    background: linear-gradient(90deg, #535c9f 0%, #0d0f1d 100%);
                }
                .label_warpper {
                    bottom: -70px;
                    right: 270px;
                }
            }
            .list_item:nth-child(3) {
                animation-delay: 1s;
                display: flex;
                justify-content: flex-end;
                .cover {
                    margin-right: 110px;
                    width: 550px;
                    height: 272px;
                }
                .label_warpper {
                    bottom: 16px;
                    right: 562px;
                }
            }
        }
    }
    @keyframes blur-text {
        0% {
            opacity: 0;
            transform: translate3d(0px, 6px, 0px);
            filter: blur(5px);
        }
        50% {
            opacity: 0;
            transform: translate3d(0px, 6px, 0px);
        }
        100% {
            opacity: 1;
            transform: translate3d(0px, 0px, 0px);
            filter: blur(0px);
        }
    }
    @keyframes blur-text2 {
        0% {
            opacity: 0;
            transform: translate3d(0px, 15px, 0px);
            filter: blur(5px);
        }
        20% {
            opacity: 0;
            transform: translate3d(0px, 15px, 0px);
            filter: blur(5px);
        }
        70% {
            transform: translate3d(0px, 0px, 0px);
        }
        100% {
            opacity: 1;
            transform: translate3d(0px, 0px, 0px);
            filter: blur(0px);
        }
    }
    @keyframes fade-text {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }
    @keyframes fade-text {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }
    @keyframes photo_item {
        0% {
            opacity: 0;
            transform: translate3d(0px, 150px, 0px);
        }

        100% {
            opacity: 1;
            transform: translate3d(0px, 0px, 0px);
        }
    }
}
</style>
