/*
 * @Author: PC
 * @Date: 2024-10-11 11:37:13
 * @LastEditTime: 2024-10-29 09:21:34
 * @Description: 请您输入文件描述
 */
export default [
    require(`@/assets/images/home/logo.png`),
    require(`@/assets/images/home/scene1/banner.png`),
    require(`@/assets/images/home/scene1/bg.png`),
    require(`@/assets/images/home/scene2/guest/guest0.png`),
    require(`@/assets/images/home/scene2/guest/guest0_bg.png`),
    require(`@/assets/images/home/scene2/guest/guest0_card.png`),
    require(`@/assets/images/home/scene2/guest/guest1.png`),
    require(`@/assets/images/home/scene2/guest/guest1_bg.png`),
    require(`@/assets/images/home/scene2/guest/guest1_card.png`),
    require(`@/assets/images/home/scene2/guest/guest2.png`),
    require(`@/assets/images/home/scene2/guest/guest2_bg.png`),
    require(`@/assets/images/home/scene2/guest/guest2_card.png`),
    require(`@/assets/images/home/scene2/guest/guest3.png`),
    require(`@/assets/images/home/scene2/guest/guest3_bg.png`),
    require(`@/assets/images/home/scene2/guest/guest3_card.png`),
    require(`@/assets/images/home/scene2/guest/guest4.png`),
    require(`@/assets/images/home/scene2/guest/guest4_bg.png`),
    require(`@/assets/images/home/scene2/guest/guest4_card.png`),

    require("@/assets/images/home/scene3/banner_bg.png"),
    require("@/assets/images/home/scene3/banner0.png"),
    require("@/assets/images/home/scene3/banner1.png"),
    require("@/assets/images/home/scene3/banner2.png"),
    require("@/assets/images/home/scene3/bg0.png"),
    require("@/assets/images/home/scene3/bg1.png"),
    require("@/assets/images/home/scene3/timeLine_img0.png"),
    require("@/assets/images/home/scene3/timeLine_img1.png"),
    require("@/assets/images/home/scene3/timeLine_img2.png"),
    require("@/assets/images/home/scene4/banner0.gif"),
    require("@/assets/images/home/scene4/banner1.gif"),
    require("@/assets/images/home/scene4/banner2.gif"),
    require("@/assets/images/home/scene4/qr_code.png"),

];
